import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

export const columns = [
  {
    name: "date",
    type: "datetime",
    sortable: true,
    searchable: true,
    noWrap: true,
  },
  {
    name: "customer",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "facility",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "facilities.name",
  },
  {
    name: "policy",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "policies.name",
  },
  {
    name: "user",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "users.username",
  },
  {
    name: "reference",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "closed",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "summary",
    type: "text",
    sortable: true,
    searchable: true,
    noWrap: true,
  },
  {
    name: "subtotal",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "discount",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "tax",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "total",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "commission",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "cost",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "profit",
    type: "balance",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "daily_counter",
    type: "number",
    sortable: true,
    searchable: true,
  },
];

export default function SalesList() {
  return <ResourceListPage url="sales" columns={columns} />;
}
