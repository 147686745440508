import React, { Fragment, useMemo } from "react";
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  PrintButton,
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";
import { sumField } from "commons/helpers/utils";
import usePrintTemplates from "commons/hooks/usePrintTemplates";
import useTranslate from "commons/hooks/useTranslate";
import { prop } from "ramda";
import TableReceiptRow from "./TableReceiptRow";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function ReceiptPrintCard({
  model,
  linesByCategory,
  base,
  tables = [],
  onPrintRequested,
}) {
  const { t } = useTranslate();
  const classes = useStyles();
  const count =
    model && model.lines
      ? new Set(model.lines.map(prop("product_id"))).size
      : 0;
  const subtotal = model.subtotal || 0;
  const discount = model.discount || 0;
  const tax = model.tax || 0;
  const total = model.total || 0;
  const paid = useMemo(
    () => sumField("amount")(model.payments),
    [model.payments]
  );
  const remaining = useMemo(() => total - paid, [total, paid]);
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();
  const { translations } = usePrintTemplates();

  const getItemName = (lang, type, id, fallback = "") => {
    const item = translations.find(
      (t) =>
        t.translatable_type === type &&
        t.translatable_id === id &&
        t.language_id === lang
    );
    return item ? item.translation : fallback;
  };

  const onPrint = (id) => {
    onPrintRequested(() => {
      onPrintRequest(id);
    });
  };

  return (
    <>
      <PrintButton
        onPrintRequest={onPrint}
        filterFunc={(item) => item[base] === true}
        renderFunc={(handleMenu) => (
          <Button
            onClick={handleMenu}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            size="large"
            id="fd-receipt-print-btn"
          >
            {t("printReceipt")}
          </Button>
        )}
      />
      <div style={{ display: "none" }}>
        <PrintTemplate
          active={active}
          onPrintCompleted={onPrintCompleted}
          content={(language) => (
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="2">
                      {model.entity && model.entity.name}
                    </TableCell>
                  </TableRow>
                  {model.entity && model.entity.address && (
                    <TableRow>
                      <TableCell colSpan="2">{model.entity.address}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      colSpan={active && active.print_daily_counter ? 1 : 2}
                    >
                      {getItemName(
                        language,
                        "labels",
                        1,
                        t("reference_number")
                      )}{" "}
                      {model.reference}
                    </TableCell>
                    {active && active.print_daily_counter && (
                      <TableCell>
                        {getItemName(
                          language,
                          "labels",
                          25,
                          t("daily_counter")
                        )}{" "}
                        {model.daily_counter}
                      </TableCell>
                    )}
                  </TableRow>
                  {tables.length > 0 && (
                    <TableReceiptRow
                      model={model}
                      tables={tables}
                      getItemName={getItemName}
                      language={language}
                    />
                  )}
                </TableBody>
              </Table>
              {model && model.lines && (
                <Table className="print-content-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>
                          {getItemName(language, "labels", 2, t("line"))}
                        </strong>
                      </TableCell>
                      {!active.group_by_category && (
                        <TableCell>
                          <strong>
                            {getItemName(language, "labels", 4, t("price"))}
                          </strong>
                        </TableCell>
                      )}
                      {!active.group_by_category && (
                        <TableCell>
                          <strong>
                            {getItemName(language, "labels", 3, t("quantity"))}
                          </strong>
                        </TableCell>
                      )}
                      <TableCell>
                        <strong>
                          {getItemName(language, "labels", 7, t("total"))}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableFooter>
                    {!active.group_by_category && (
                      <FooterRow
                        colSpan={3}
                        label={getItemName(language, "labels", 5, t("count"))}
                        value={count}
                      />
                    )}
                    {subtotal !== total && (
                      <FooterRow
                        colSpan={active.group_by_category ? 1 : 3}
                        label={getItemName(
                          language,
                          "labels",
                          6,
                          t("subtotal")
                        )}
                        value={subtotal / 100}
                      />
                    )}
                    {discount > 0 && (
                      <FooterRow
                        colSpan={active.group_by_category ? 1 : 3}
                        label={getItemName(
                          language,
                          "labels",
                          8,
                          t("discounts")
                        )}
                        value={discount / 100}
                      />
                    )}
                    {tax > 0 && (
                      <FooterRow
                        colSpan={active.group_by_category ? 1 : 3}
                        label={getItemName(language, "labels", 9, t("taxes"))}
                        value={tax / 100}
                      />
                    )}
                    <FooterRow
                      colSpan={active.group_by_category ? 1 : 3}
                      className={classes.highlight}
                      label={getItemName(language, "labels", 7, t("total"))}
                      value={total / 100}
                    />
                    <FooterRow
                      colSpan={active.group_by_category ? 1 : 3}
                      label={getItemName(language, "labels", 10, t("paid"))}
                      value={paid / 100}
                    />
                    <FooterRow
                      colSpan={active.group_by_category ? 1 : 3}
                      label={getItemName(
                        language,
                        "labels",
                        11,
                        t("remaining")
                      )}
                      value={remaining / 100}
                    />
                  </TableFooter>
                  <TableBody>
                    {active.group_by_category
                      ? Object.values(linesByCategory).map((line, index) => (
                          <TableRow key={index}>
                            {!line.name ? (
                              <TableCell>
                                {getItemName(
                                  language,
                                  "labels",
                                  26,
                                  t("others")
                                )}
                              </TableCell>
                            ) : (
                              <TableCell>
                                {getItemName(
                                  language,
                                  "categories",
                                  line.product_id,
                                  line.name
                                )}
                              </TableCell>
                            )}
                            <TableCell>{line.total / 100}</TableCell>
                          </TableRow>
                        ))
                      : model.lines.map((line) => (
                          <Fragment key={line.product_id}>
                            <TableRow>
                              <TableCell>
                                {getItemName(
                                  language,
                                  "products",
                                  line.product_id,
                                  line.name
                                )}
                              </TableCell>
                              <TableCell>{line.price / 100}</TableCell>
                              <TableCell>{Number(line.actual)}</TableCell>
                              <TableCell>{line.subtotal / 100}</TableCell>
                            </TableRow>
                            {line.discounts.map((discount) => (
                              <TableRow key={discount.discount_id}>
                                <TableCell colSpan={2}>
                                  {getItemName(
                                    language,
                                    "discounts",
                                    discount.discount_id,
                                    discount.name
                                  )}
                                </TableCell>
                                <TableCell>{Number(discount.value)}</TableCell>
                                <TableCell>
                                  {discount.applied_value / 100}
                                </TableCell>
                              </TableRow>
                            ))}
                            {line.taxes.map((tax) => (
                              <TableRow key={tax.tax_id}>
                                <TableCell colSpan={2}>
                                  {getItemName(
                                    language,
                                    "taxes",
                                    tax.tax_id,
                                    tax.name
                                  )}
                                </TableCell>
                                <TableCell>{Number(tax.value)}</TableCell>
                                <TableCell>{tax.applied_value / 100}</TableCell>
                              </TableRow>
                            ))}
                          </Fragment>
                        ))}
                  </TableBody>
                </Table>
              )}
              {model.print_notes && (
                <Typography variant="body1">{model.print_notes}</Typography>
              )}
            </div>
          )}
        />
      </div>
    </>
  );
}

function FooterRow({ label, value, colSpan, ...props }) {
  return (
    <TableRow {...props}>
      <TableCell colSpan={colSpan}>
        <Typography variant="body1" noWrap>
          <strong>{label}</strong>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" noWrap>
          <strong>{value}</strong>
        </Typography>
      </TableCell>
    </TableRow>
  );
}
