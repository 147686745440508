import React, { useState, Fragment } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import {
  PrintButton,
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";
import usePrintTemplates from "commons/hooks/usePrintTemplates";
import { useStocksByFacility } from "commons/pages/sales/components/StockManager";
import dayjs from "dayjs";

const notFulfilled = (l) => !Boolean(l.fulfilled);

export default function StocksPrintCard({
  model = {},
  onPrintRequested,
  products = [],
  facilities = [],
  printComponents = false,
  autoFulfilOnPrint = false,
}) {
  const { t } = useTranslate();
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();
  const { translations } = usePrintTemplates();
  const [cachedStocks, setCachedStocks] = useState([]);
  const { facilitiesNames, productsNames, stocksByFacility } =
    useStocksByFacility(cachedStocks, products, facilities);

  const getItemName = (lang, type, id, fallback = "") => {
    const item = translations.find(
      (t) =>
        t.translatable_type === type &&
        t.translatable_id === id &&
        t.language_id === lang
    );
    return item ? item.translation : fallback;
  };

  const onPrint = (id) => {
    setCachedStocks(
      model.lines
        ? model.lines.flatMap((line) => line.stocks.filter(notFulfilled))
        : []
    );
    onPrintRequested(
      () => {
        onPrintRequest(id);
      },
      autoFulfilOnPrint
        ? {
            ...model,
            lines: model.lines.map((l) => {
              return {
                ...l,
                stocks: l.stocks.map((s) => {
                  if (notFulfilled(s)) {
                    return { ...s, fulfilled: dayjs() };
                  } else {
                    return s;
                  }
                }),
              };
            }),
          }
        : model
    );
  };

  return (
    <>
      <PrintButton
        onPrintRequest={onPrint}
        filterFunc={(item) => item.stocks === true}
        renderFunc={(handleMenu) => (
          <Button
            variant="contained"
            color="primary"
            onClick={handleMenu}
            fullWidth
            disableElevation
            size="large"
            id="fd-storage-print-btn"
          >
            {t("printStocks")}
          </Button>
        )}
      />
      <div style={{ display: "none" }}>
        <PrintTemplate
          active={active}
          onPrintCompleted={onPrintCompleted}
          content={(language) =>
            Object.keys(stocksByFacility).map((facility) => {
              const facilityStocks = stocksByFacility[facility];
              const filtered = facilityStocks
                .filter((rec) => Number(rec.quantity) !== 0)
                .filter((rec) => {
                  if (
                    !rec.operation_type ||
                    ["sale", "purchase", "printOnly"].includes(
                      rec.operation_type
                    )
                  )
                    return true;
                  return printComponents
                    ? "componentOut" === rec.operation_type
                    : "compositeOut" === rec.operation_type;
                });
              return (
                <Fragment key={facility}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>{facilitiesNames[facility]}</TableCell>
                        <TableCell>
                          {getItemName(
                            language,
                            "labels",
                            1,
                            t("reference_number")
                          )}{" "}
                          {model.reference}
                        </TableCell>
                        {active && active.print_daily_counter && (
                          <TableCell>
                            {getItemName(
                              language,
                              "labels",
                              25,
                              t("daily_counter")
                            )}{" "}
                            {model.daily_counter}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table className="print-content-table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <strong>
                            {getItemName(language, "labels", 2, t("line"))}
                          </strong>
                        </TableCell>
                        <TableCell>
                          <strong>
                            {getItemName(language, "labels", 3, t("quantity"))}
                          </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filtered.map((line, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            {t(line.quantity > 0 ? "enter" : "exit")}
                          </TableCell>
                          <TableCell>
                            {getItemName(
                              language,
                              "products",
                              line.product_id,
                              productsNames[line.product_id]?.name
                            )}
                            {line.tags && `(${line.tags})`}
                          </TableCell>
                          <TableCell>{Math.abs(line.quantity)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="page-break-always" />
                </Fragment>
              );
            })
          }
        />
      </div>
    </>
  );
}
